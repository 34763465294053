/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-03",
    versionChannel: "nightly",
    buildDate: "2024-09-03T00:05:47.657Z",
    commitHash: "4c59f5e49db7c25fe6e0bea5fea7f42ec21cf848",
};
